import React from "react"
import Collection from "./collection"

const CollectionsList = ({collections, title}) => {

   let index=0;
    return (
        <div>
            <p></p>
        {/* <h3>{title}</h3> */}
        <ul>
            {collections.sort((a, b) => a.country.localeCompare(b.country)).map((collectionData) => {
                return <Collection collection={collectionData} key={index++} />
            })}
        </ul>
        </div>
    )
}
export default CollectionsList