import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import JSONData from "../data/public-collections.json"
import CollectionsList from "../components/collectionsList"

const Collections = (props) => {

    return (
        <Layout>
            <Seo title="Collections" />
            <h2>Works in Public Collections</h2>
            <CollectionsList collections={JSONData}/>
            </Layout>
            )
            }

 export default Collections          